@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700;800&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Inter', sans-serif;
  ;
}

::-webkit-scrollbar {
  width: 2px;
  height: 2px;

}

::-webkit-scrollbar-track {
  /* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);  */
  border-radius: 10px;

}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: black !important;

  /* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);  */
}

:root {
  --primary: #CFBD73;
  --secondary: #F0F0F0;
  --colour-black: #000;
  --theme-orange-color: #ED8C2F;
  --theme-color: #212121;
  --colour-white: #fff;

}




/* .header {
  background: var(--colour-one) !important;
} */

#main-nav {
  /* height: 6rem; */
  width: 100%;
  background-color: #090909;
  /* box-shadow: 0 3px 20px rgba(0, 0, 0, 0.2); */
  display: flex;
  position: fixed;
  z-index: 10;
  justify-content: space-between;
  padding: 0px 100px;
}



#main-nav .icon-navs {
  float: left;
  display: flex;
  justify-content: center;
  align-items: center;
}

#main-nav .icon-navs ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 26px;
}

#main-nav .react-header-dropdown {
  background-color: unset;
  border: none;
  outline: none;
  box-shadow: unset;
  padding: 0 !important;
}

#main-nav .dropdown-item {
  font-size: 16px;
  color: var(--theme-color);
  font-weight: 500;
  padding: 8px;
  text-align: center;
}

.log-out-btn{
  font-size: 16px;
  color: var(--colour-white);
  font-weight: 500;
  padding: 10px 30px;
  text-align: center;
  border: none;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  font-weight: 600;
  background: var(--theme-orange-color);

}
.product-price-list p {
  margin: 0;
}

.header-dropdown.dropdown-menu.show {
  padding: 0;
  display: block !important;
  margin-top: 10px;
  transform: translate(50px, 33px);
}

/* .dropdown-toggle::after{
  display: none !important;
} */
.dropdown-item.active,
.dropdown-item:active {
  background-color: var(--theme-orange-color) !important;
  color: white !important;
}

#main-nav a.active.dropdown-item {
  background-color: var(--theme-orange-color);
  color: white;
  font-weight: 500;
}

#main-nav .icon-navs ul svg {
  color: var(--theme-color) !important;
  fill: var(--colour-white);
  width: 30px;
  height: 30px;
}

.toggle-class {
  display: none;
  cursor: pointer;
}

.form-btn {
  padding: 10px 30px;
  border-radius: 20px;
  border: 0 !important;
  outline: 0 !important;
  background: var(--theme-orange-color);
  color: white;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  font-weight: 500;
}

.form-btn:active {
  box-shadow: none;
}


.fixedd {
  background: #ffffff !important;
  position: fixed;
  width: 100%;
  padding-top: 0;
  padding-bottom: 0;
  transition: .5s;
  box-shadow: 0 0 100px rgb(163 163 163 / 20%);
  z-index: 99;
}


/*Styling logo*/
#main-nav .logo {
  padding: 1vh 1vw;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

#main-nav .logo img {
  width: 100px;
  object-fit: contain;
  /* border: 1px solid var(--theme-orange-color);
  padding: 5px;
  border-radius: 100%; */
}

/*Styling Links*/
#main-nav .nav-links {
  display: flex;
  list-style: none;
  width: 88vw;
  padding: 0 0.7vw;
  justify-content: space-evenly;
  align-items: center;
  text-transform: uppercase;
}

#main-nav .nav-links li a {
  text-decoration: none;
  margin: 0 0.7vw;
}

#main-nav .nav-links li a:hover {
  color: var(--primary);
}

#main-nav .nav-links li {
  position: relative;
}

#main-nav .nav-links li a::before {
  content: "";
  display: block;
  height: 3px;
  width: 0%;
  background-color: var(--primary);
  position: absolute;
  bottom: 0;
  transition: all ease-in-out 250ms;
  margin: 0 0 0 10%;
}

#main-nav .nav-links li a:hover::before {
  width: 80%;
}

/*Styling Buttons*/
#main-nav .login-button {
  background-color: transparent;
  border: 1.5px solid #f2f5f7;
  border-radius: 2em;
  padding: 0.6rem 0.8rem;
  margin-left: 2vw;
  font-size: 1rem;
  cursor: pointer;

}

#main-nav .login-button:hover {
  color: #131418;
  background-color: #f2f5f7;
  border: 1.5px solid #f2f5f7;
  transition: all ease-in-out 350ms;
}

#main-nav .join-button {
  color: #131418;
  background-color: #61DAFB;
  border: 1.5px solid #61DAFB;
  border-radius: 2em;
  padding: 0.6rem 0.8rem;
  font-size: 1rem;
  cursor: pointer;
}

#main-nav .join-button:hover {
  color: #f2f5f7;
  background-color: transparent;
  border: 1.5px solid #f2f5f7;
  transition: all ease-in-out 350ms;
}

/*Styling Hamburger Icon*/
#main-nav .hamburger div {
  width: 30px;
  height: 3px;
  background: var(--colour-white);
  margin: 5px;
  transition: all 0.3s ease;
  cursor: pointer;
}

/* #main-nav .hamburger {
  display: none;
} */

#main-nav .nav-links {
  display: none !important;
}

/*Stying for small screens*/

/*Animating Hamburger Icon on Click*/
.toggle .line1 {
  transform: rotate(-45deg) translate(-5px, 6px);
}

.toggle .line2 {
  transition: all 0.7s ease;
  width: 0;
}

.toggle .line3 {
  transform: rotate(45deg) translate(-5px, -6px);
}



#main-nav .nav {
  display: inline-flex;
  position: relative;
  /* overflow: hidden; */
  max-width: fit-content;
  /* background-color: #fff; */
  padding: 0 20px;
  border-radius: 40px;
  /* box-shadow: 0 10px 40px rgba(159, 162, 177, 0.8); */
}

#main-nav .nav-item {
  color: var(--colour-white);
  padding: 20px;
  text-decoration: none;
  transition: 0.3s;
  margin: 0 6px;
  z-index: 1;
  font-weight: 500;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 19px;
}

#main-nav .nav-item:before {
  content: "";
  position: absolute;
  bottom: -6px;
  left: 0;
  width: 100%;
  height: 5px;
  background-color: #dfe2ea;
  border-radius: 8px 8px 0 0;
  opacity: 0;
  transition: 0.3s;
}

#main-nav .is-active {
  color: var(--theme-orange-color);
  border-bottom: 2px solid var(--theme-orange-color);
}

#main-nav .nav-item:not(.is-active):hover:before {
  bottom: 0;

}

#main-nav .nav-item:not(.is-active):hover {
  color: var(--theme-orange-color);
}

#main-nav .nav-indicator {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 4px;
  transition: 0.4s;
  height: 5px;
  z-index: 1;
  border-radius: 8px 8px 0 0;
}

.side-bar {
  display: none;
}

.side-bar .container {
  position: relative;
  /* z-index: 999; */
  padding: 0;
  margin: 0;
}

.sidebar {
  background: #fdf2f9;
  transition: all .5s;
  max-width: 1000px;
  width: 400px;
  height: 100vh;
  z-index: 999;
  position: fixed;
  /* border-right: 5px solid var(--theme-orange-color); */
}

.sidebar.collapsed {
  max-width: 0;
  overflow: hidden;
  border-right: 0;
}

.content {
  position: absolute;
}

.content button {
  margin: 10px;
  background-color: var(--theme-orange-color);
  border: none;
  border-radius: 2px;
}

.content button div {
  width: 20px;
  height: 3px;
  background-color: aliceblue;
  margin: 3px 0;
}

#head {
  display: flex;
  justify-content: center;
  position: relative;
  top: 30px;
}

#head .logo {
  font-size: 22px;
  font-weight: 700;
  font-style: italic;
  padding-right: 15px;
  color: var(--theme-color);
}

.list {
  list-style: none;
  padding: 5px 0;
}

.list li {
  margin: 5px;
  padding: 5px 0px;
  border-radius: 0 !important;

}


.list .active a {
  color: var(--theme-orange-color) !important;
  border-bottom: 2px solid var(--theme-orange-color);
}

.list li:hover a {
  border-radius: 5px;
  /* background-color: #7B61FF; */
  color: var(--theme-orange-color) !important;
  border-bottom: 2px solid var(--theme-orange-color);
  border-radius: 0 !important;
}

.list a {
  color: var(--theme-color);
  padding: 10px;
  text-decoration: none;
  transition: 0.3s;
  margin: 0 6px;
  z-index: 1;
  font-weight: 500;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 19px;
}

/* --------------------------------------------------------------------------------------- */






/* ------------------------------Banner Section Style-------------------------------------- */
.banner-section {
  float: left;
  width: 100%;
  height: 100vh;
  background-image: url(../public/img/bg.jpg);
  background-position: bottom;
  background-repeat: no-repeat;
  /* transform: scaleX(-1); */
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 150px;

}

.banner-left-section {
  float: left;
  width: 100%;
  padding: 0;
}

.banner-left-section h2 {
  font-size: 56px;
  color: var(--theme-color);
}

.loader-heading {
  display: flex;
  gap: 20px;
}

.loader {
  position: relative;
  overflow: hidden;
  border-right: 3px solid;
  width: 0px;
  animation: typewriter 2s steps(10) infinite alternate, blink 0.5s steps(10) infinite;
}

.loader-text {
  font-size: 56px;
  font-weight: 500;
  background: linear-gradient(to right, #ED8C2F, #d49054);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@keyframes typewriter {
  0% {
    width: 0px;
  }

  100% {
    width: 240px;
  }
}

@keyframes blink {
  0% {
    border-right-color: rgba(255, 255, 255, .75);
    ;
  }

  100% {
    border-right-color: transparent;
  }
}

.shop-now {
  margin-top: 2rem;
  padding: 11px 50px;
  border-radius: 20px;
  border: 0 !important;
  outline: 0 !important;
  background: var(--theme-orange-color);
  color: white;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  font-weight: 500;
  font-size: 20px;
}

/* ------------------------------Banner Section Style End-------------------------------------- */



/* --------------------------Collection Section Style------------------------------------------------ */


/* Slider */
.carousel-caption {
  position: unset !important;
  display: flex;
  gap: 30px;
}

.carousel-caption {
  justify-content: center;
}


.carousel-indicators [data-bs-target] {
  background: var(--theme-orange-color) !important;
  border-top: 10px solid white !important;
  border-bottom: 10px solid white !important;
}

.carousel-control-prev-icon {
  background: unset !important;
}

.carousel-control-next-icon {
  background: unset !important;
}



.title_font-heading {
  float: left;
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 0px !important;
  /* padding-right: 35px !important; */
}

.title_font-heading p {
  text-align: center;
  /* padding: 0 50px ; */
  width: 50%;
  margin-bottom: 40px;
}

h2.title_font {
  font-size: 32px;
  color: var(--theme-orange-color);
}

#heading-text-center {
  margin: 0 !important;
}

.wp-block-spacer {
  float: left;
  width: 100%;
  height: 100px;
}

.collection-section {
  float: left;
  width: 100%;
  /* padding: 100px 0; */
  position: relative;
}

.collection-section #menu {
  width: 100% !important;
  position: relative;
}

.rec-item-wrapper {
  /* flex: 0 0 auto !important; */
  width: 398px !important;
}

.collection-section .rec-arrow-left {
  position: absolute;
  left: 11%;
  top: 50%;
  font-size: 16px !important;
  width: 45px !important;
  height: 45px !important;
  min-height: 45px !important;
  min-width: 45px !important;
  line-height: 30px !important;
}

.collection-section .rec-arrow-left:hover {
  background: var(--theme-orange-color) !important;
  color: white !important;

}

.collection-section .rec-arrow-right {
  position: absolute;
  right: 11%;
  top: 50%;
  font-size: 16px !important;
  width: 45px !important;
  height: 45px !important;
  min-height: 45px !important;
  min-width: 45px !important;
  line-height: 30px !important;
}

.collection-section .rec-swipable {
  gap: 30px !important;
}

.collection-section .rec-arrow-right:hover {
  background: var(--theme-orange-color) !important;
  color: white !important;
}

.collection-section .vUlQj {
  background: var(--theme-orange-color) !important;
  color: white !important;
  border-color: var(--theme-orange-color) !important;
  box-shadow: var(--theme-orange-color) 0px 0px 1px 3px !important;
}

.iWchow:hover:enabled,
.iWchow:focus:enabled {
  color: rgb(255, 255, 255);
  background-color: var(--theme-orange-color) !important;
  box-shadow: var(--theme-orange-color) 0px 0px 2px 0px !important;
}

.woods_card {
  /* background: #fdf2f98c; */
  padding-bottom: 0.5rem;
  border-radius: 12px !important;
  width: 100%;
  /* border: 2px solid #80808024; */
}

.woods_content {
  background: #fdf2f98c;
  height: 350px;
  /* width: 100%; */
  border-radius: "4px 4px 0 0";
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px;
}

.woods_content img {
  height: 100%;
  object-fit: contain;
}

.common_flexing {
  display: flex;
  justify-content: center;
  gap: 0px;
  align-items: center;
  flex-direction: column;

}

.small_font {
  line-height: 1.5;
  margin-top: 5px !important;
  font-size: 13px;
  font-weight: 500;
  /* font-family: 'Inria Serif',Verdana,Helvetica,serif; */
}


.medium_font {
  /* font: 600 1.2rem 'Inter', sans-serif;; */
  font-size: 16px;
  line-height: 1.4;
  font-weight: 500;
  /* font-family: "Jost,sans-serif"; */
}

#heading-name {
  font-weight: 400 !important;
  color: var(--theme-color);
}

#price-text {
  font-size: 16px;
  font-weight: 600;
  color: #000;
  /* border-radius: 4px !important;
  padding: 5px 8px !important;
  margin-bottom: 5px !important;
  background: rgba(12, 11, 9, 0.6) !important;
  border-bottom: 1px solid rgba(12, 11, 9, 0.6) !important; */
}

.addtpcart-div {
  /* padding: 5px; */
  display: flex;
  justify-content: center;
}

.view-cart-btn {
  background-color: unset !important;
  border: none !important;
  text-decoration: underline !important;
  color: var(--theme-color) !important;
  font-weight: 600;
  cursor: pointer;
}

.cart_btn {
  border-radius: 20px;
  border: 0 !important;
  outline: 0 !important;
  background: var(--theme-orange-color);
  color: white;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  font-weight: 600;
  margin-top: 15px;
  padding: 13px 30px;

  margin-bottom: 15px;
}

/* --------------------------Collection Section Style End------------------------------------------------ */


/* --------------------------Highlight Section Style------------------------------------------------ */


.highlight-section {
  float: left;
  width: 100%;
  height: 50vh;
  background-color: #e2d6de8c;
  /* background-image: url(../public/img/highligth-bg.jpg); */
  background-position: bottom;
  background-repeat: no-repeat;

  /* transform: scaleX(-1); */
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 150px;

}

.highlight-left-section {
  float: left;
  width: 50% !important;
  display: flex;
  align-items: center;
  padding: 0;
  flex-direction: column;
  padding-right: 50px !important;
  justify-content: center;
}

.highlight-left-section .hightlight-content {
  font-size: 28px;
  margin: 20px 0;
  color: var(--theme-color);
  margin-bottom: 10px !important;
}

.sale-heading {
  font-size: 48px;
  color: var(--theme-color);
  font-weight: 600;
  /* text-align: center; */
  text-transform: uppercase;
  margin: 15px 0;

}


.order-now {
  margin: 20px 0;
  padding: 13px 50px;
  /* border-radius: 20px; */
  border: 0 !important;
  outline: 0 !important;
  background: var(--theme-color);
  color: white;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  font-weight: 500;
  font-size: 20px;
}

.highlight-right-section {
  float: left;
  width: 50% !important;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.highlight-right-section img {
  height: 70%;
  background-color: unset !important;
}

.arrow-img {
  height: 13%;
  position: absolute;
  left: 15%;
  margin-top: 14%;
  transform: rotate(-40deg);
  opacity: 60%;
}

/* --------------------------Highlight Section Style End------------------------------------------------ */

/* ----------------------------Feartured Card-------------------------------------------------------- */

.category-section {
  float: left;
  width: 100%;
  /* padding: 100px 0; */
}

.category_card {
  /* background: #fdf2f98c; */
  padding-bottom: 0.5rem;
  /* border-radius: 12px !important; */
  width: 100%;
  /* border: 2px solid #80808024; */
}

.category-heading-name {
  text-align: center;
  font-weight: 500 !important;
  font-size: 16px;
  color: var(--theme-color);
  margin: 20px 0;
}

.category_content {
  background: #fdf2f98c;
  height: 300px;
  /* width: 100%; */
  border-radius: "4px 4px 0 0";
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px;
}

.category_content img {
  height: 100%;
}

/* ---------------------------Feartured Card End------------------------------------------- */


/* ---------------------------Footer Style------------------------------------------- */

.footer {
  float: left;
  width: 100%;
  font-size: 14px;
  background-color: #1f1f24;
  padding: 50px 0;
  color: rgba(255, 255, 255, 0.7);
  padding-bottom: 0;
}

.footer .icon {
  margin-right: 15px;
  font-size: 24px;
  line-height: 0;
}

.footer h4 {
  font-size: 16px;
  font-weight: bold;
  position: relative;
  padding-bottom: 5px;
  color: #fff;
}

.footer .footer-links {
  margin-bottom: 30px;
}

.footer .footer-links ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer .footer-links ul li {
  padding: 10px 0;
  display: flex;
  align-items: center;
}

.footer .footer-links ul li:first-child {
  padding-top: 0;
}

.footer .footer-links ul a {
  color: rgba(255, 255, 255, 0.6);
  transition: 0.3s;
  display: inline-block;
  line-height: 1;
}

.footer .footer-links ul a:hover {
  color: #fff;
}

.footer .social-links a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid rgba(255, 255, 255, 0.2);
  font-size: 16px;
  color: rgba(255, 255, 255, 0.7);
  margin-right: 10px;
  transition: 0.3s;
}

.footer .social-links a:hover {
  color: #fff;
  border-color: #fff;
}

.footer .copyright {
  text-align: center;
  padding: 20px 0;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.footer .credits {
  padding-top: 4px;
  text-align: center;
  font-size: 13px;
}

.footer .credits a {
  color: #fff;
}

/* ---------------------------Footer Style End------------------------------------------- */
/* ----------------------Login Form Style------------------------------------------------ */

.login-modal .modal-header {
  position: relative;
  border: none;
}


.close-img {
  position: absolute;
  right: -1%;
  width: 30px;
  background: black;
  border-radius: 100%;
  top: -9%;
  cursor: pointer;
  opacity: unset !important;
  /* color: white !important; */
}


.login-section {
  float: left;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 100vh; */
}



.form {
  padding: 4rem 0;
  border-style: solid;
  border-left: 0;
  border-right: 0;
  border-bottom: 0;
  width: 45rem;
  /* border-top: 1px var(--theme-color); */
  border-width: 2px;
  /* border-style: groove; */
  border-color: rgb(192, 192, 192);
  border-image: initial;

}

.form__legend {
  text-align: center;
  width: unset;
  padding: 0 0.5rem;
  float: unset;
  font-size: 20px;
  color: rgb(192, 192, 192);
  font-weight: 400;
}

.form__body {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form__group {
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
}

.input-feilds {
  float: left;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 2rem;
}

.input-feilds .error {
  padding-left: 10px;
}

.input-feilds .form__input {
  display: block;
  width: 100% !important;
}

.form__input {
  display: block;
  width: 100%;
  max-width: 45rem;
  background-color: #EEF5F3;
  border: none;
  outline: none;
  height: 3.5rem;
  border-radius: 2rem;
  padding: 0 1.5rem;
  transition: background 0.3s;
  color: #88908E !important;
  font-size: 16px;
  margin-bottom: 10px;
}

.form__input-half {
  display: inline-block;
  width: 48.5%;
}

.form__input-checkbox:checked {
  filter: invert(100%) hue-rotate(118deg) brightness(1.2);
}

.form__input::placeholder {
  font-size: 16px;
  font-weight: 400;
  font-family: inherit;
  opacity: 0.75;
}

.form__input:focus,
.form__inputactive {
  background: #FFFFFF;
  border: 1px solid #E1EAE7;
}

.switch {
  z-index: 100;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #28B498;
  padding: 0 2rem;
  color: #FFFFFF;
}

.switch__header {
  margin-bottom: 4rem;
}

.switch__text-container {
  text-align: center;
}

.login,
.signup {
  display: flex;
  opacity: 1;
  flex-basis: 0;
  flex-shrink: 1;
  flex-grow: 1;
  padding: 0rem 4rem;
  flex-direction: column;
  align-items: center;
  /* overflow-Y: auto; */
}

.login__header,
.signup__header {
  margin-bottom: 0;
}

.login__byline,
.signup__byline {
  margin-bottom: 3rem;
}

.haveAn-text {
  margin: 0 !important;
}

.social-media__container {
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;
  justify-content: center;
  margin-bottom: 3rem;
}

.social-media__icon:not(:last-child) {
  margin-right: 1rem;
}

.header {
  text-align: center;
  font-weight: 200;
}

.icon {
  cursor: pointer;
}

.forgot-pass {
  text-align: right;
  float: left;
  width: 100%;
  padding-right: 20px;
}

.login-section .btn {
  /* padding: 15px 150px; */
  width: 23.5rem;
  min-height: 3.5rem;
  background-color: var(--theme-orange-color);
  border-radius: 3rem;
  outline: 0;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFFFFF;
  font-family: "Raleway", sans-serif;
  font-size: 22px;
  line-height: 2rem;
  text-align: center;
  margin: 3.2rem 0;
  transition: 0.3s;
  margin-bottom: 1rem;
}

/* .btn:hover, .btn:active {
  background-color:  var(--theme-orange-color);
} */
.btn-white {
  background-color: #FFFFFF;
  color: black;
  box-shadow: 0 12px 24px rgba(34, 51, 49, 0.13);
}

.login-section .btn:hover {
  background-color: var(--theme-orange-color);
  color: var(--colour-white);
}

.btn-white:hover,
.btn-white:active {
  background-color: #FFFFFF;
  color: var(--theme-orange-color);
  box-shadow: none;
}

.href-color {
  color: var(--theme-orange-color) !important;
  cursor: pointer;
  font-size: 16px !important;
}




.login.hide-view {
  transform: translateX(50vw);
}

.smooth {
  transition: transform 0.3s linear, opacity 0.3s ease-in-out, flex-grow 0.9s cubic-bezier(0.19, 1, 0.22, 1);
}

/* ----------------------Login Form Style End------------------------------------------------ */



/* ----------------------Sign Up Form Style------------------------------------------------ */

.signup-section {
  float: left;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 100vh; */
}

.signup-section .input__group {
  float: left;
  width: 100%;
  text-align: right;
  gap: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.signup-section .btn {
  /* padding: 15px 150px; */
  width: 23.5rem;
  min-height: 3.5rem;
  background-color: var(--theme-orange-color);
  border-radius: 3rem;
  outline: 0;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFFFFF;
  font-family: "Raleway", sans-serif;
  font-size: 22px;
  line-height: 2rem;
  text-align: center;
  margin: 3.2rem 0;
  transition: 0.3s;
  margin-bottom: 1rem;
}

/* .btn:hover, .btn:active {
  background-color:  var(--theme-orange-color);
} */
.btn-white {
  background-color: #FFFFFF;
  color: black;
  box-shadow: 0 12px 24px rgba(34, 51, 49, 0.13);
}

.signup-section .btn:hover {
  background-color: var(--theme-orange-color);
  color: var(--colour-white);
}

/* ----------------------Sign Up Form Style End------------------------------------------------ */





/* -------------------------Product style----------------------------------- */


.heading-products {
  padding: 3rem 0;
  padding-bottom: 3rem;
}

/* cart items */

.cart-page {
  margin: 70px auto;
  min-height: 60vh;
  float: left;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  /* margin-top: 10rem; */
}

table {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid rgba(33, 33, 33, 0.1);
  padding: 10px;
}

.remove-btn {
  background-color: unset !important;
  border: unset !important;
  outline: none !important;
  box-shadow: none !important;
  color: var(--theme-orange-color) !important;
  font-size: 13px !important;
  border-bottom: 0.5555555555px solid var(--theme-orange-color) !important;
}

.product-price-list {
  float: left;
  width: 100%;
  text-align: left;
}

.cart-info {
  display: flex;
}

.heading-tr {
  padding-bottom: 20px !important;
}

th {
  text-align: left;
  padding: 5px 20px;
  color: #ffffff;
  background: var(--theme-orange-color);
  font-weight: 500;
}

.amt-texts{
  font-weight: 600 !important;
}
.total-amts {
  font-weight: 800;
  font-size: 20px;
}

.total-amt-tr{
  border-top: 0.5555px solid #DFDFDF;
}
td {
  padding: 15px 20px;
}

td input {
  width: 40px;
  height: 30px;
  padding: 5px;
  border: none;
  text-align: center;
}

td input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.cart-info-img {
  background: #fdf2f98c;
  width: 100px;
  height: 100px;
  /* width: 100%; */
  border-radius: "4px 4px 0 0";
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3px;
  margin-right: 10px
}

.quantity-item {
  display: flex;
  justify-content: center;
  align-items: center;
  /* gap: 10px; */
  border: 1px solid var(--theme-color);
  height: 35px;
  width: 40%;

  padding: 0 10px;

}
.quantity-item svg {
  fill: var(--colour-black)  ;
  font-size: 14px;
  cursor: pointer;
}

.quantity-item svg:hover {
  color: gray !important;
  font-weight: 800;
}

.quantity-item .plus {
  margin: 0;

}

.quantity-td img {
  width: 18px;
  cursor: pointer;
}

td a {
  color: var(--theme-orange-color);
  font-size: 12px;
}

td img {
  width: 100%;
  object-fit: contain;
  height: 100%;
  /* margin-right: 10px; */
}

.coupon-section {
  float: left;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 0 !important;
  flex-wrap: wrap;
}

.coupon-left {
  float: left;
  display: flex;
  justify-content: flex-start;
  gap: 10px;
}

.coupon-code {
  padding: 12px 16px;
  font-weight: 500;
  color: var(--theme-color);
  border: 1px solid #BCBCBC !important;


}

.coupon-code::placeholder {
  font-weight: 500;
  color: var(--theme-color)
}

.aplly-btn {
  padding: 10px 30px;
  border-radius: 20px;
  border: 0 !important;
  outline: 0 !important;
  background: var(--theme-orange-color);
  color: white;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  font-weight: 500;
  font-size: 16px;
}

.update-btn {
  padding: 10px 30px;
  border-radius: 20px;
  border: 0 !important;
  outline: 0 !important;
  background: var(--theme-orange-color);
  color: white;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  font-weight: 500;
  font-size: 16px;
}

.total-price {
  display: flex;
  justify-content: flex-end;
  padding: 0 !important;
  margin-top: 20px !important;

}

#total-price-checkout {
  justify-content: unset;
}

.total-price table {
  border-top: 3px solid var(--theme-orange-color);
  width: 100%;
  max-width: 400px;
  background-color: #fdf2f98c;
}

.total-price td {
  padding: 18px 20px !important;
}

td:last-child {
  text-align: right;
}

th:last-child {
  text-align: right;
}




.submit-btn {
  padding: 10px 0px;
  border-radius: 20px;
  border: 0 !important;
  outline: 0 !important;
  background: var(--theme-orange-color);
  color: white;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  font-weight: 500;
  font-size: 16px;
  float: left;
  text-align: center;
  width: 100%;
  margin: 20px 70px !important;
  text-decoration: unset;
}





/* account page */
.account-page {
  padding: 50px 0;
  background: radial-gradient(#fff, #ffd6d6);
}

.form-container {
  background: #ffffff;
  width: 300px;
  height: 400px;
  position: relative;
  text-align: center;
  padding: 20px 0;
  margin: auto;
  box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.form-container span {
  font-weight: bold;
  padding: 0 10px;
  color: #555555;
  cursor: pointer;
  width: 100px;
  display: inline-block;
}

.form-btn {
  display: inline-block;
}

.form-container form {
  max-width: 300px;
  padding: 0 20px;
  position: absolute;
  top: 130px;
  transition: transform 1s;
}

.account-page form input {
  width: 100%;
  height: 30px;
  margin: 10px 0;
  padding: 0 10px;
  border: 1px solid #ccc;
}

.account-page form .btn {
  width: 100%;
  border: none;
  cursor: pointer;
  margin: 10px 0;
}

form .btn:focus {
  outline: none;
}

#LoginForm {
  left: -300px;
}

#RegForm {
  left: 0;
}

form a {
  font-size: 12px;
}

#Indicator {
  width: 100px;
  border: none;
  background: #ff523b;
  height: 3px;
  margin-top: 8px;
  transform: translateX(100px);
  transition: transform 1s;
}

/* media query for less than 600 screen size */
@media only screen and (max-width: 991px) {

  .heading-products {
    padding-top: 0 !important;
    padding-bottom: 1rem;
  }

  .cart-page .container,
  .container-md,
  .container-sm {
    max-width: 820px;
    padding: 0 30px;
  }

  .cart-page {
    margin: 120px auto;
  }
}

@media only screen and (max-width: 600px) {
  .row {
    text-align: center;
  }

  .col-2,
  .col-3,
  .col-4 {
    flex-basis: 100%;
  }

  .single-product .row {
    text-align: left;
  }

  .single-product .col-2 {
    padding: 20px 0;
  }

  .single-product h1 {
    font-size: 26px;
    line-height: 32px;
  }

  /* .cart-info p {
    display: none;
  } */

  .cart-info {
    display: flex;
    /* flex-wrap: wrap; */
    justify-content: flex-start;
  }

  .cart-info-img {
    background: #fdf2f98c;
    width: 100%;
    height: 100%;
  }

  .cart-info p {
    margin: 0;
    text-align: left;
  }

  .cart-info small {
    text-align: left;
  }

  /* .coupon-right{
  margin-top: 20px;
} */
  .coupon-code {
    width: 180px;
  }

  .coupon-left {
    align-items: flex-start;
    flex-direction: column;
  }

  .coupon-section {
    align-items: flex-start;
  }

  .aplly-btn {
    padding: 10px 15px;
  }

  .total-price td {
    text-align: left;
  }

  td input {
    width: 20px;
    height: 30px;
    padding: 5px;
    border: none;
    text-align: center;
  }
}

@media only screen and (max-width: 479px) {

  .cart-info {
    display: flex;
    /* flex-wrap: wrap; */
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
  }

  .cart-info-img {
    background: #fdf2f98c;
    width: 100px;
    height: 100px;
  }

}

/* -------------------------Product style End----------------------------------- */


/* -------------------------Checkout Style--------------------------------- */

.heading-top {
  margin-bottom: 30px;
  font-size: 48px;
}

.checkout-section {
  float: left;
  width: 100%;
  margin: 70px 0;
}

.checkout-section .accordion-button::after {
  display: none;
}


.alert-info svg:not(:root) {
  background-color: var(--theme-orange-color) !important;
  border-radius: 100%;
  color: white !important;
  fill: white;
  margin-right: 10px;
}

.checkout-section .accordion-item:first-of-type .accordion-button {
  font-size: 15px !important;
  color: var(--theme-color);
  border: 1px solid var(--theme-orange-color);
  border-radius: unset !important;
  box-shadow: unset;
  outline: none;
  word-break: unset !important;
}

.checkout-section .accordion-item:first-of-type .accordion-button svg:not(:root) {
  background-color: var(--theme-orange-color) !important;
  border-radius: 100%;
  color: white !important;
  fill: white;
  margin-right: 10px;
}

.accordion-span {
  /* text-decoration: underline; */
  padding-left: 5px;
  color: var(--theme-color);
  word-break: break-all;
}

.accordion-section {
  /* float: left; */
  width: 100%;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  gap: 5px;
  align-items: flex-start;
  padding: 0px 0 !important;
  flex-wrap: wrap;
}

.accordion-left {
  /* float: left; */
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  padding-bottom: 10px;
}

.accordion-code {
  padding: 12px 16px;
  font-weight: 500;
  color: var(--theme-color);
  border: 1px solid #BCBCBC !important;


}

.accordion-code::placeholder {
  font-weight: 500;
  color: var(--theme-color)
}

.aplly-accord-btn {
  padding: 10px 30px;
  border-radius: 20px;
  border: 0 !important;
  outline: 0 !important;
  background: var(--theme-orange-color);
  color: white;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  font-weight: 500;
  font-size: 16px;
}

.checkout-section label {
  text-align: left;
  margin-bottom: 5px;
}

.checkout-section .form-control {
  border-radius: 7px !important;
  box-shadow: unset !important;
}

.checkout-section .custom-select {
  display: block;
  width: 100%;
  /* padding: 0.500rem 0.75rem; */
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  /* appearance: none; */
  background-color: var(--bs-body-bg);
  background-clip: padding-box;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  outline: unset !important;
  box-shadow: unset !important;
}

.css-t3ipsp-control {
  border: none !important;
  outline: none !important;
  box-shadow: unset !important;
}

.css-13cymwt-control {
  border: none !important;
  outline: none !important;
  box-shadow: unset !important;
}

.checkout-section .input-group-text {
  border-radius: 7px 0 0 7px;
}

.checkout-section #username {
  border-radius: 0 7px 7px 0 !important;
}

.checkout-section .custom-control-input {
  margin-right: 5px !important;
}


.list-group {
  background-color: #fdf2f98c !important;
}

.list-group-item {
  background-color: #fdf2f98c !important;
  border: unset !important;
}

#list-group-button {
  justify-content: center;
  margin: 30px 0;
}

.checkout-section .total-price table {
  border-top: 3px solid var(--theme-orange-color);
  width: 100%;
  max-width: 400px;
  background-color: #fdf2f98c;
  align-items: center;
  display: table-caption;
  padding: 0;
}

.price-txt {
  border-top: 1px solid #e6e0e0;
  border-bottom: 1px solid #e6e0e0;
}

.alerts-text {
  font-size: 14px !important;
  display: flex;
  justify-content: center;
  width: 100%;
  text-align: left !important;
  margin: 0 !important;
}

.alerts-text svg {
  width: 80px;
  height: 24px;
}

.alert-section {
  float: left;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 20px;
  padding-bottom: 10px;
}

.continue-btn-check {
  padding: 10px 0px;
  border-radius: 20px;
  border: 0 !important;
  outline: 0 !important;
  background: var(--theme-orange-color);
  color: white;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  font-weight: 500;
  font-size: 16px;
  float: left;
  text-align: center;
  width: 100%;
  margin: 20px 50px !important;
  text-decoration: unset;
}

#checkout-row .total-price {
  margin: 0 !important;
}

#checkout-row .text-muted {
  color: var(--bs-heading-color) !important;
  font-weight: 500;
}

.text-danger {
  font-size: 14px !important;
  margin-top: 5px;
}

/* -------------------------Checkout Style End--------------------------------- */






/* ------------------------Responsive clases styles------------------------------------- */

@media (max-width:1800px) {

  .collection-section .rec-arrow-left {
    position: absolute;
    left: 3%;
    top: 50%;
    font-size: 16px !important;
    width: 45px !important;
    height: 45px !important;
    min-height: 45px !important;
    min-width: 45px !important;
    line-height: 30px !important;
  }

  .collection-section .rec-arrow-right {
    position: absolute;
    right: 3%;
    top: 50%;
  }
}

@media (max-width:1400px) {

  .collection-section .rec-arrow-left {
    position: absolute;
    left: 2%;
    top: 50%;
    font-size: 16px !important;
    width: 45px !important;
    height: 45px !important;
    min-height: 45px !important;
    min-width: 45px !important;
    line-height: 30px !important;
  }

  .collection-section .rec-arrow-right {
    position: absolute;
    right: 2%;
    top: 50%;
  }

  .rec-item-wrapper {
    /* flex: 0 0 auto !important; */
    width: 338px !important;
  }
}

@media (max-width:1300px) {
  .collection-section .rec-arrow-left {
    display: none !important;
  }

  .collection-section .rec-arrow-right {
    display: none !important;
  }

  .sale-heading {
    font-size: 35px;
  }

  .highlight-left-section .hightlight-content {
    font-size: 26px;
    margin-bottom: 0 !important;
  }

  .woods_content {
    background: #fdf2f98c;
    height: 300px;
  }

  .carousel-caption {
    justify-content: center;
  }

  .carousel-caption #menu {
    width: unset !important;
  }

  .arrow-img {
    height: 10%;
    left: 7%;
    margin-top: 13%;
  }

  .highlight-right-section img {
    height: 80%;
    background-color: unset !important;
  }

  .highlight-section {
    padding: 0 50px;
  }

  .title_font-heading p {
    width: 60%;
  }

  .category_content {
    height: 220px;
  }

  .order-now {
    font-size: 16px;
  }

  .loader {
    animation: typewriter 2s steps(3) infinite alternate, blink 0.5s steps(5) infinite;
  }

  .banner-left-section h2 {
    font-size: 42px;
  }

  .loader-text {
    font-size: 42px;
  }

  .continue-btn-check {
    margin: 20px 40px !important;
  }
}

@media (max-width:1199px) {

  .rec-item-wrapper {
    /* flex: 0 0 auto !important; */
    width: 276px !important;
  }

  .banner-left-section h2 {
    font-size: 35px;
    text-align: left;
  }

  .loader-text {
    font-size: 35px;
  }

  #main-nav .nav-item {
    font-size: 16px;
  }

  #main-nav {
    padding: 0 64px;
  }

  .shop-now {
    font-size: 16px;
  }

  .banner-section {
    padding: 0 70px;
  }

  .banner-section {
    background-position: right;
  }

  .woods_content {
    background: #fdf2f98c;
    height: 200px;
    padding: 20px;
  }

  .category-section .row {
    justify-content: center;
  }

  .category-section .col-lg-3 {
    width: unset;
    margin-bottom: 0 !important;
  }

  .continue-btn-check {
    margin: 20px 25px !important;
  }
}

@media (max-width:991px) {
  .rec-item-wrapper {
    /* flex: 0 0 auto !important; */
    width: 200px !important;
  }

  #main-nav .nav {
    display: none;
  }

  .toggle-class {
    display: unset;
    z-index: 9999;
  }

  .side-bar {
    display: unset;
  }

  .sale-heading {
    font-size: 27px;
  }

  .highlight-left-section .hightlight-content {
    font-size: 22px;
  }

  .order-now {
    margin: 10px 0;
    padding: 10px 35px;
  }

  .highlight-right-section img {
    height: 100%;
    background-color: unset !important;
  }

  .arrow-img {
    height: 10%;
    left: 4%;
    margin-top: 15%;
  }

  .title_font-heading p {
    width: 100%;
  }

  /* ----------Product Page style-------------- */


  .quantity-item {
    width: 70%;
  }

  div#checkout-row {
    flex-direction: column;

  }

  /*
  .needs-validation{
    width: 100% !important;
  }
  .order-md-2{
    width: 100% !important;
  } */
  #checkout-row .row {
    text-align: left !important;
  }

  #checkout-row .total-price {
    float: left;
  }

  #checkout-row .col-md-8 {
    width: 100% !important;
    text-align: left;
  }

  #checkout-row .col-md-4 {
    width: 100% !important;
    text-align: left;
  }

  .continue-btn-check {
    margin: 20px 50px !important;
  }
  #main-nav .dropdown{
    display: none;
  }
  button#dropdown-basic {
    background: unset;
    background-color: unset;
    border: none;
    outline: none;
    box-shadow: unset;
    padding: 0 !important;
}
svg#Layer_1 {
  color: var(--theme-color) !important;
  fill: var(--theme-color);
  width: 30px;
  height: 30px;
}
/* .sidebar-close-li {
} */
.sidebar-close-li img{
  width: 22px;
  position: absolute;
 right: 3%;
}
#nav-form-btn{
  display: none;
}
#main-nav .icon-navs ul{
  justify-content: flex-end;
  gap: 0;
}
#main-nav .icon-navs ul .toggle-class{
  margin-left: 20px;
}
.form{
  width: 100%;
}
.header {
  text-align: center;
  font-weight: 200;
  font-size: 22px;
}
.login__byline, .signup__byline{
  font-size: 14px;
  text-align: center;
  padding-top: 5px;
}
}

@media (max-width:767px) {
  .quantity-td{
    width: 20%;
    padding: 0px !important;
  }
  .rec-item-wrapper {
    /* flex: 0 0 auto !important; */
    width: 185px !important;
  }

  .title_font-heading p {
    width: 100%;
  }

  .cart_btn {
    font-size: 14px;
  }

  .container,
  .container-sm {
    max-width: 665px !important;
  }

  .sale-heading {
    font-size: 24px;
  }

  .highlight-left-section {
    padding-right: 0 !important;
  }

  #checkout-row .total-price {
    justify-content: flex-start !important;
  }

  div#checkout-row {
    flex-direction: column-reverse;

  }
  .sidebar {
    width: 100%;
  }


}

@media (max-width:600px) {
  .rec-item-wrapper {
    /* flex: 0 0 auto !important; */
    width: 220px !important;
  }

  /* .rec-slider{
  left: auto !important;
} */
  .banner-left-section {
    float: left;
    width: 100% !important;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-top: -25% !important;
  }

  .highlight-right-section {
    display: none !important;
  }

  .highlight-left-section {
    width: 100% !important;
    padding: 0;
  }

  .highlight-left-section .hightlight-content {
    font-size: 24px;
  }

  .sale-heading {
    font-size: 28px;
  }

  .order-now {
    margin: 10px 0;
    padding: 10px 40px;
  }

  .highlight-section {
    padding: 0 50px;
    height: 35vh;
  }

  .arrow-img {
    height: 10%;
    left: 18%;
    margin-top: 23%;
  }

  .banner-section {
    padding: 0 50px;
  }

  #main-nav {
    padding: 0 50px;
  }

  .carousel-caption {
    flex-direction: column;
    padding: 0 0px;

  }

  .carousel-caption #menu {
    margin: 0 !important;
  }

  .woods_content {
    background: #fdf2f98c;
    height: 250px;
    padding: 40px;
  }

  .footer h4 {
    text-align: left;
  }

  .footer p {
    text-align: left;
  }

  .category_content {
    height: 220px;
    padding: 40px;
  }

  .wp-block-spacer {
    height: 50px;
  }

  .banner-section {
    background-position: center;
  }



  /* ----------Product Page style-------------- */


}

@media (max-width:479px) {
  .quantity-item {
    width: 100%;
}
  td {
    padding: 15px 20px;
}
  .rec-item-wrapper {
    /* flex: 0 0 auto !important; */
    width: 330px !important;
  }

  .banner-section {
    height: 70vh;
  }

  h2.title_font {
    font-size: 28px;
    color: var(--theme-orange-color);
  }

  .title_font-heading p {
    width: 100%;
    font-size: 13px;
    margin-bottom: 15px;
  }

  .banner-section {
    background-position: right;
  }

  .sale-heading {
    font-size: 23px;
  }

  .highlight-left-section .hightlight-content {
    font-size: 22px;
  }


  .category-heading-name {
    text-align: center;
    font-weight: 500 !important;
    font-size: 14px;
    color: var(--theme-color);
    margin: 20px 0;
  }

  #main-nav {
    padding: 0 10px;
  }

  .banner-left-section h2 {
    font-size: 30px;
    text-align: left;
  }

  .loader-text {
    font-size: 30px;
  }

  .category-section .col-lg-3 {
    width: 100%;
  }

  .arrow-img {
    height: 10%;
    left: 6%;
    margin-top: 30%;
  }

  .loader {
    overflow: hidden;
    animation: typewriter 2s steps(2) infinite alternate, blink 0.5s steps(2) infinite;
  }

  .banner-section {
    padding: 0 10px;
  }

  .checkout-section .accordion-item:first-of-type .accordion-button {
    display: block;
  }
  .header {
    text-align: center;
    font-weight: 200;
    font-size: 20px;
}
#heading-top-checkout {
  
  margin-top: 4rem;
}
}

@media (max-width:414px) {

  .loader {
    animation: typewriter 2s steps(5) infinite alternate, blink 0.5s steps(0) infinite;
  }

  .continue-btn-check {
    margin: 20px 35px !important;
  }
  .total-price td {
    padding: 18px 16px !important;
}
}

/* ------------------------Responsive clases styles end------------------------------------- */